import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import { Table } from 'react-bootstrap';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Users.module.scss';
import Select from 'react-select';
import Tooltip from '@mui/material/Tooltip';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchUsers();
  }, [currentPage, itemsPerPage, role]);

  const fetchUsers = async () => {
    try {
      const filters = { role: role ? role.value : '' };
      const data = await adminService.fetchUsersWithPagination(currentPage, itemsPerPage, filters);
      setUsers(data.users);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRoleChange = (selectedRole) => {
    setRole(selectedRole);
  };

  const renderSourcesTooltip = (sources) => (
<Tooltip
  title={
    <div style={{ fontSize: '16px' }}>
      {sources.map((source, index) => (
        <div key={index}>{source}</div>
      ))}
    </div>
  }
  arrow
>
  <span>{sources.length} sources</span>
</Tooltip>

  );

  const roleOptions = [
    { value: '', label: 'All' },
    { value: 'Admin', label: 'Admin' },
    { value: 'User', label: 'User' },
  ];

  return (
    <div className={styles.usersContainer}>
      <h1>Users</h1>

      <div className={styles.filterContainer}>
        <div>
          <label>Role Filter:</label>
          <Select
            value={role}
            onChange={handleRoleChange}
            options={roleOptions}
            isClearable
            placeholder="Select role"
            className={styles.roleSelect}
          />
        </div>
      </div>

      <div className={styles.usersTableContainer}>
        <table striped bordered hover className={styles.tableFullWidth}>
          <thead>
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th>Sources</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>
                  {user.sources.length > 1 ? (
                    renderSourcesTooltip(user.sources)
                  ) : (
                    user.sources[0] || 'No Sources'
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PaginationComponent
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default Users;
