import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import programService from '../../../services/programService';
import styles from './EditStandradDealModal.module.scss';

const EditStandradDealModal = ({
  isOpen,
  onRequestClose,
  selectedProgram,
  selectedProgramRegion,
  onSubmit,
}) => {
  const initialDeal = selectedProgramRegion.ProgramRegionDeals && selectedProgramRegion.ProgramRegionDeals[0]?.Deal ? selectedProgramRegion.ProgramRegionDeals[0].Deal : {};
  
  const [dealData, setDealData] = useState({
    baseline: initialDeal.baseline || '',
    CPAbeforeCommission: initialDeal.CPAbeforeCommission || '',
    CPA: initialDeal.CPA || '',
    revShareBeforeCommission: initialDeal.revShareBeforeCommission || '',
    revshare: initialDeal.revshare || '',
  });

  const [overrideAll, setOverrideAll] = useState(false);
  const [overrideSpecific, setOverrideSpecific] = useState(false);
  const [sources, setSources] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);

  // Hämta källor för regionen när "Override specific sources" är valt
  useEffect(() => {
    if (overrideSpecific) {
      fetchSources();
    }
  }, [overrideSpecific]);

  const fetchSources = async () => {
    try {
      const sourcesData = await programService.getProgramRegionStandardDealSources(selectedProgramRegion.id);
      setSources(sourcesData);
    } catch (error) {
      console.error('Error fetching sources:', error);
    }
  };

  const handleDealChange = (field, value) => {
    setDealData({ ...dealData, [field]: value });
  };

  const handleSubmit = () => {
    const updateData = {
      dealData,
      overrideAll,
      selectedSources: overrideSpecific ? selectedSources : [],
    };
    onSubmit(updateData);
  };

  const handleSourceSelection = (sourceId) => {
    setSelectedSources((prevSelected) =>
      prevSelected.includes(sourceId)
        ? prevSelected.filter((id) => id !== sourceId)
        : [...prevSelected, sourceId]
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modalContent}>
        <h3>Edit Deal</h3>
        <div>
          <p><strong>Program: </strong>{selectedProgram?.name}</p>
          <p><strong>Region: </strong>{selectedProgramRegion.Region.name}</p>
        </div>
        <div>
          <h6>Current deal</h6>
          <p><strong>Baseline:</strong> {initialDeal.baseline} €</p>
          <p><strong>CPA Before:</strong> {initialDeal.CPAbeforeCommission} €</p>
          <p><strong>CPA:</strong> {initialDeal.CPA} €</p>
          <p><strong>Revshare Before:</strong> {initialDeal.revShareBeforeCommission}%</p>
          <p><strong>Revshare:</strong> {initialDeal.revshare}%</p>
        </div>
        <p className={styles.warningText}>This change will only affect the current region.</p>

        {/* Override Options */}
        {/* Deal Inputs */}
        <div className={styles.inputGroup}>
          <div className={styles.formField}>
            <label htmlFor="baseline">New Baseline</label>
            <input
              id="baseline"
              type="number"
              value={dealData.baseline}
              onChange={(e) => handleDealChange('baseline', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="CPAbeforeCommission">New CPA Before</label>
            <input
              id="CPAbeforeCommission"
              type="number"
              value={dealData.CPAbeforeCommission}
              onChange={(e) => handleDealChange('CPAbeforeCommission', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="cpa">New CPA</label>
            <input
              id="cpa"
              type="number"
              value={dealData.CPA}
              onChange={(e) => handleDealChange('CPA', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="revShareBeforeCommission">New Revshare Before</label>
            <input
              id="revShareBeforeCommission"
              type="number"
              value={dealData.revShareBeforeCommission}
              onChange={(e) => handleDealChange('revShareBeforeCommission', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="revshare">New Revshare</label>
            <input
              id="revshare"
              type="number"
              value={dealData.revshare}
              onChange={(e) => handleDealChange('revshare', e.target.value)}
            />
          </div>
        </div>

        <div className={styles.overrideOptions}>
          <div className={styles.checkboxContainer}>
            <input
              type="checkbox"
              id="overrideAll"
              checked={overrideAll}
              onChange={() => {
                setOverrideAll(!overrideAll);
                if (!overrideAll) setOverrideSpecific(false);
              }}
            />
            <label htmlFor="overrideAll">Override all sources with this deal</label>
          </div>

          <div className={styles.checkboxContainer}>
            <input
              type="checkbox"
              id="overrideSpecific"
              checked={overrideSpecific}
              onChange={() => {
                setOverrideSpecific(!overrideSpecific);
                if (!overrideSpecific) setOverrideAll(false);
              }}
            />
            <label htmlFor="overrideSpecific">Override specific sources</label>
          </div>
        </div>

        {/* Source Selection */}
        {overrideSpecific && (
          <div className={styles.sourcesList}>
            <h6>Select Sources</h6>
            <div className={styles.sourcesRows}>
            {sources.length > 0 ? (
              sources.map((source) => (
                <div key={source.id} className={styles.checkboxContainer}>
                  <input
                    type="checkbox"
                    id={`source-${source.id}`}
                    checked={selectedSources.includes(source.id)}
                    onChange={() => handleSourceSelection(source.id)}
                  />
                  <label htmlFor={`source-${source.id}`}>{source.name}</label>
                </div>
              ))
            ) : (
              <p>No sources available for this region.</p>
            )}
            </div>
          </div>
        )}


        <div className={styles.modalActions}>
          <button onClick={handleSubmit} className={styles.submitButton}>Submit</button>
          <button onClick={onRequestClose} className={styles.cancelButton}>Cancel</button>
        </div>
      </div>
    </Modal>
  );
};

export default EditStandradDealModal;
