import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Unauthorized.css';

const Unauthorized = () => {
  useEffect(() => {
    document.title = 'Savvy - Unauthorized';
  }, []);  

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate("/"); // Gå tillbaka till föregående sida
  };

  return (
    <div className="unauthorized-container">
      <h1 className="unauthorized-title">403 - Unauthorized</h1>
      <p className="unauthorized-message">You do not have permission to access this page.</p>
      <button className="unauthorized-button" onClick={handleGoBack}>
        Go Back
      </button>
    </div>
  );
};

export default Unauthorized;
