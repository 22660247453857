import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL + "programs/";

const getSourcePrograms = async (sourceId, filters) => {
  try {
    const params = { sourceId, ...filters };
    const response = await axios.get(API_URL, {
      params,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching source programs:", error);
    throw error;
  }
};

const joinProgram = async (sourceId, programRegionId) => {
  try {
    const response = await axios.post(
      `${API_URL}join-program-request/`,
      { sourceId, programRegionId },
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    console.error("Error requesting program join:", error);
    throw error;
  }
};

const fetchPerformance = async (
  sourceId,
  brandId,
  dateRange,
  breakdownOption,
  perDay
) => {
  try {
    const params = {
      sourceId,
      brandId,
      breakdownOption,
      perDay,
    };

    // Lägg till start- och slutdatum om dateRange finns
    if (dateRange) {
      params.startDate = dateRange.startDate.toISOString().split("T")[0]; // Formatera till YYYY-MM-DD
      params.endDate = dateRange.endDate.toISOString().split("T")[0];
    }

    const response = await axios.get(`${API_URL}performance`, {
      params,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching performance data:", error);
    throw error;
  }
};

const fetchAllPerformance = async (
  sourceId,
  brandId,
  dateRange,
  breakdownOption,
  perDay
) => {
  try {
    const params = {
      sourceId,
      brandId,
      breakdownOption,
      perDay,
    };

    // Lägg till start- och slutdatum om dateRange finns
    if (dateRange) {
      params.startDate = dateRange.startDate.toISOString().split("T")[0]; // Formatera till YYYY-MM-DD
      params.endDate = dateRange.endDate.toISOString().split("T")[0];
    }

    const response = await axios.get(`${API_URL}all-performance`, {
      params,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching performance data:", error);
    throw error;
  }
};

const fetchAllRegions = async () => {
  try {
    const response = await axios.get(`${API_URL}all-regions`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching regions:", error);
    throw error;
  }
};

const fetchAllBrands = async () => {
  try {
    const response = await axios.get(`${API_URL}all-brands`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching brands:", error);
    throw error;
  }
};

const fetchAllVerticals = async () => {
  try {
    const response = await axios.get(`${API_URL}all-verticals`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching verticals:", error);
    throw error;
  }
};

const getProgramRegionStandardDealSources = async (programRegionId) => {
  try {
    const response = await axios.get(
      `${API_URL}${programRegionId}/standard-sources`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching program region sources:", error);
    throw error;
  }
};

export default {
  getProgramRegionStandardDealSources,
  fetchAllBrands,
  fetchAllVerticals,
  fetchAllRegions,
  fetchPerformance,
  joinProgram,
  getSourcePrograms,
  fetchAllPerformance,
};
