import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import styles from './EarningsChart.module.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  ChartDataLabels
);

const EarningsChart = ({ earningsData, chartPeriod, onPeriodChange }) => {
  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);

  useEffect(() => {
    prepareChartData();
  }, [earningsData]);

  const prepareChartData = () => {
    const allDates = generateDateRange(chartPeriod);
    const dataMap = {};
    const programMap = {};
  
    // Organisera data per datum och program
    earningsData.forEach((item) => {
      const date = new Date(item.date).toLocaleDateString(undefined, {
        month: 'short',
        day: 'numeric',
      });
      const totalEarning =
        parseFloat(item.totalCPAIncome || 0) +
        parseFloat(item.totalRevShareIncome || 0);
  
      if (!dataMap[date]) {
        dataMap[date] = totalEarning;
        programMap[date] = [];
      }
  
      // Lägg till programintäkter per datum
      item.programs.forEach((program) => {
        programMap[date].push({
          programName: program.programName,
          totalIncome: parseFloat(program.totalIncome || 0),
        });
      });
    });
  
    const dates = [];
    const earnings = [];
  
    allDates.forEach((date) => {
      dates.push(date);
      earnings.push(dataMap[date] || 0);
    });
  
    const maxYValue = Math.max(...earnings);
    const suggestedMax = maxYValue === 0 ? 100 : Math.ceil((maxYValue * 1.15) / 50) * 50;
  
    // Skapa data för diagrammet
    setChartData({
      labels: dates,
      datasets: [
        {
          label: 'Revenue',
          data: earnings,
          fill: true,
          backgroundColor: 'rgba(252, 163, 17, 0.2)',
          borderColor: '#FCA311',
          pointRadius: 3,
          datalabels: {
            align: 'end',
            anchor: 'end',
            formatter: (value) => `€${value.toFixed(2)}`,
            font: { size: 10 },
            color: '#FCA311',
          },
          cubicInterpolationMode: 'monotone',
        },
      ],
    });
  
    // Anpassad Tooltip Callback
    setChartOptions({
      plugins: {
        legend: { display: false },
        tooltip: {
          enabled: true,
          callbacks: {
            // Ta bort `title` callback för att inte visa datumet
            label: (context) => {
              const date = context.label;
              const totalEarning = context.raw;
              const programs = programMap[date] || [];
    
              // Skapa standardrad med totalintäkter
              const totalLabel = `Total: €${totalEarning.toFixed(2)}`;
    
              // Skapa en uppdelning av programintäkter, en per rad
              const programLabels = programs
                .filter((program) => program.totalIncome > 0)
                .map(
                  (program) =>
                    `${program.programName}: €${program.totalIncome.toFixed(2)}`
                );
    
              // Returnera endast programlabels utan datum
              return [totalLabel, ...programLabels];
            },
          },
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          titleColor: '#FCA311',
          bodyColor: '#ffffff',
          borderWidth: 1,
          padding: 10,
          bodySpacing: 5,
          displayColors: false,
        },
        datalabels: {
          display: false, // Döljer datalabels i själva diagrammet
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: suggestedMax,
          grid: { display: false },
          ticks: {
            callback: (value) => `€${value.toFixed(0)}`,
            autoSkip: true,
          },
          border: { display: false },
        },
        x: {
          grid: { display: false },
          offset: true,
          ticks: {
            callback: (val, index) => dates[index],
          },
        },
      },
    });
    
    
  };
  

  const generateDateRange = (period) => {
    const dates = [];
    const today = new Date();
    const startDate = new Date();

    if (period === 'last7days') {
      startDate.setDate(today.getDate() - 6);
    } else if (period === 'lastMonth') {
      startDate.setMonth(today.getMonth() - 1);
    }

    for (let d = new Date(startDate); d <= today; d.setDate(d.getDate() + 1)) {
      dates.push(
        new Date(d).toLocaleDateString(undefined, {
          month: 'short',
          day: 'numeric',
        })
      );
    }
    return dates;
  };

  const handleChartPeriodChange = (event) => {
    onPeriodChange(event.target.value);
  };

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chartHeader}>
        <div className={styles.headerTheme}>
          <h3>Activity</h3>
          <div className={styles.chartLegends}>
            <span className={styles.earningsLegend}>Revenue</span>
          </div>
        </div>
        <div className={styles.chartFilter}>
          <p>Updates every day</p>
          <FormControl variant="outlined" size="small">
            <InputLabel>Period</InputLabel>
            <Select
              value={chartPeriod}
              onChange={handleChartPeriodChange}
              label="Period"
            >
              <MenuItem value="last7days">Last 7 Days</MenuItem>
              <MenuItem value="lastMonth">Last Month</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {chartData ? (
        <Line data={chartData} options={chartOptions} />
      ) : (
        <p>No earnings data available for the selected period.</p>
      )}
    </div>
  );
};

export default EarningsChart;
