// src/components/ProgramsPage.jsx

import React, { useState, useEffect } from 'react';
import programService from '../../services/programService';
import sourceService from '../../services/sourceService';
import Select from 'react-select';
import styles from './Programs.module.scss';
import Modal from 'react-modal';
import placeholder from '../../images/placeholder.jpg';
import Flag from 'react-world-flags'; // Importera flaggbibliotek
import Tooltip from '@mui/material/Tooltip';
import { style } from '@mui/system';

Modal.setAppElement('#root');

const ProgramsPage = () => {
  useEffect(() => {
    document.title = 'Savvy - Programs';
  }, []);

  // States för att lagra data och filter
  const [regionIds, setRegionIds] = useState([]);
  const [regions, setRegions] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [sources, setSources] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);
  const [status, setStatus] = useState(null);
  const [brandId, setBrandId] = useState(null);
  const [programToJoin, setProgramToJoin] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [verticals, setVerticals] = useState([]);
  const [selectedVerticals, setSelectedVerticals] = useState([]);
  const [copyStatus, setCopyStatus] = useState(null);

  // Hämta initial data vid komponentens mount
  useEffect(() => {
    fetchSources();
    fetchRegions();
    fetchBrands();
    fetchVerticals();
  }, []);

  // Funktioner för att hämta data
  const fetchSources = async () => {
    try {
      const response = await sourceService.getUserSources();
      setSources(response);
      setSelectedSource(response[0] || null); // Automatiskt välj första source
    } catch (error) {
      console.error('Error fetching sources:', error);
    }
  };

  useEffect(() => {
    if (selectedSource) {
      fetchPrograms();
    }
  }, [sources]);

  const fetchBrands = async () => {
    try {
      const response = await programService.fetchAllBrands();
      setBrands(response.map(brand => ({ value: brand.id, label: brand.name })));
    } catch (error) {
      console.error('Error fetching brands:', error);
    }
  };

  const fetchRegions = async () => {
    try {
      const regionsData = await programService.fetchAllRegions();
      setRegions(regionsData);
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
  };

  const fetchVerticals = async () => {
    try {
      const response = await sourceService.fetchVerticals();
      setVerticals(response.map(vertical => ({ value: vertical.id, label: vertical.name })));
    } catch (error) {
      console.error('Error fetching verticals:', error);
    }
  };

  const fetchPrograms = async () => {
    try {
      const filters = {
        status,
        brandId,
        verticalIds: selectedVerticals.map(v => v.value),
        regionIds,
      };
      const response = await programService.getSourcePrograms(selectedSource.id, filters);
      setPrograms(response); // Spara de formaterade programmen
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  };

  const handleSourceChange = (selectedOption) => {
    const source = selectedOption ? sources.find(source => source.id === selectedOption.value) : null;
    setSelectedSource(source);
  };

  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption ? selectedOption.value : null);
  };

  const handleBrandChange = (selectedOption) => {
    setBrandId(selectedOption ? selectedOption.value : null);
  };

  const handleRegionChange = (selectedOptions) => {
    setRegionIds(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };

  const handleVerticalChange = (selectedOptions) => {
    setSelectedVerticals(selectedOptions ? selectedOptions : []);
  };

  const joinProgramModal = (program, region) => {
    setProgramToJoin({ program, region });
    setShowModal(true);
  };
  

  // Funktion för att hantera ansökan om att gå med i programmet
  const joinProgram = async (sourceId, programRegionId) => {
    try {
      await programService.joinProgram(sourceId, programRegionId);
      fetchPrograms(); // Uppdatera program efter att ha gått med
      setShowModal(false);
    } catch (error) {
      console.error('Error joining program:', error);
    }
  };

  // Statusalternativ för filter
  const statusOptions = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
  ];

  // Anpassade stilar för react-select
  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ced4da',
      borderRadius: '8px',
      padding: '2px',
      minHeight: '42px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#86b7fe',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#6c757d',
    }),
    multiValue: (styles) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      display: 'flex',
      alignItems: 'center',
      padding: '0',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      cursor: "pointer",
    }),
  };

  const copyToClipBoard = (text, id) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopyStatus(id); // Sätt copyStatus till ID:t för knappen som har kopierats
      setTimeout(() => setCopyStatus(null), 5000); // Återställ copyStatus efter 2 sekunder
    }).catch((error) => {
      console.error("Failed to copy text: ", error);
    });
  };

  
  return (
    <div className={styles.programContainer}>
      {/* Header */}
      <div className={styles.header}>
        <p>
          Select the Source and Deal type you want below to see all Programs available for you to market.
          <br />
          Click the Program to see details and get your links!
        </p>
      </div>

      {/* Filter Form */}
      <div className={styles.filterForm}>
        <div className={styles.sourceFilter}>
          <label>Select source</label>
          <Select
            name="source"
            value={selectedSource ? { value: selectedSource.id, label: selectedSource.name } : null}
            onChange={handleSourceChange}
            options={sources.map(source => ({ value: source.id, label: source.name }))}
            styles={customSelectStyles}
            placeholder="Select source"
          />
        </div>
        <div>
          <label>Brand</label>
          <Select
            name="brandId"
            value={brands.find(option => option.value === brandId) || null}
            onChange={handleBrandChange}
            options={brands}
            isClearable
            placeholder="Select a brand"
            styles={customSelectStyles}
          />
        </div>
        <div>
          <label>Status</label>
          <Select
            name="status"
            value={statusOptions.find(option => option.value === status) || null}
            onChange={handleStatusChange}
            options={statusOptions}
            isClearable
            styles={customSelectStyles}
            placeholder="Select status"
          />
        </div>

        <div className={styles.regionFilter}>
          <label>Region(s)</label>
          <Select
            isMulti
            name="regionIds"
            value={regions
              .filter(region => regionIds.includes(region.id))
              .map(region => ({
                value: region.id,
                label: region.name,
                shortCode: region.shortCode,
              }))}
            onChange={handleRegionChange}
            options={regions.map(region => ({
              value: region.id,
              label: region.name,
              shortCode: region.shortCode,
            }))}
            placeholder="Select regions"
            styles={customSelectStyles}
            isClearable
            formatOptionLabel={({ label, shortCode }, { context }) => {
              if (context === 'menu') {
                return (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Flag 
                      code={shortCode.toLowerCase()} 
                      style={{ width: '20px', height: '15px', marginRight: '8px' }} 
                      alt={`${label} flag`} 
                    />
                    <span>{label}</span>
                  </div>
                );
              } else if (context === 'value') {
                return (
                  <Tooltip title={label} arrow disableInteractive>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <Flag 
                        code={shortCode.toLowerCase()} 
                        style={{ width: '15px', height: '10px', marginRight: '5px' }} 
                        alt={`${label} flag`} 
                      />
                      <span>{label}</span>
                    </span>
                  </Tooltip>
                );
              }
              return label;
            }}
          />
        </div>

        <div className={styles.verticalFilter}>
          <div>
          <label>Vertical(s)</label>
          <Select
            name="verticalIds"
            value={selectedVerticals}
            onChange={handleVerticalChange}
            options={verticals}
            isMulti
            placeholder="Select Vertical(s)"
            styles={customSelectStyles}
          />
          </div>
          <button onClick={fetchPrograms} className={styles.applyButton}>Apply</button>
        </div>
      </div>

      {/* Program Groups */}
      <div className={styles.cardsContainer}>
        {sources.length > 0 ? programs.map((program) => (
          <div key={program.id} className={styles.programCard}>
            <div className={styles.programDetails}>
              <img
                src={program.brand.image ? `/uploads/brandLogos/${program.brand.image}` : placeholder}
                alt={program.name}
                className={styles.programImage}
              />
              <div className={styles.programInfo}>
                <h2>{program.name}</h2>
                <p>{program.verticals.map(v => v.name).join(' + ')}</p>
              </div>
            </div>

            <div className={styles.regionsContainer}>
              <div className={`${styles.regionRow} ${styles.headerRow}`}>
                <div className={styles.regionCell}></div>
                <div className={styles.regionCell}>DESCRIPTION</div>
                <div className={styles.regionCell}>BASELINE</div>
                <div className={styles.regionCell}>CPA</div>
                <div className={styles.regionCell}>REVSHARE</div>
                <div className={styles.regionCell}></div>
              </div>
              {program.regions.map((region) => {
                const {
                  id: regionId,
                  regionName,
                  regionShortCode,
                  description,
                  deal,
                  sourceProgramRegion,
                } = region;

                const effectiveDeal = deal;

                return (
                  <div key={regionId} className={styles.regionRow}>
                    <div className={styles.regionCell}>
                      <Tooltip title={regionName} arrow disableInteractive>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <Flag
                            code={regionShortCode.toLowerCase()}
                            style={{ width: '30px', height: '20px' }}
                            alt={`${regionName} flag`}
                            title={regionName}
                          />
                        </span>
                      </Tooltip>
                    </div>
                    <div className={styles.regionCell}>{description}</div>
                    <div className={styles.regionCell}>
                      {effectiveDeal && effectiveDeal.baseline > 0 ? `${effectiveDeal.baseline} €` : '-'}
                    </div>
                    <div className={styles.regionCell}>
                      {effectiveDeal && effectiveDeal.CPA > 0 ? `${effectiveDeal.CPA} €` : '-'}
                    </div>
                    <div className={styles.regionCell}>
                      {effectiveDeal && effectiveDeal.revshare ? `${(effectiveDeal.revshare * 100).toFixed(2)}%` : '-'}
                    </div>
                    <div className={styles.regionCell}>
                      {sourceProgramRegion ? (
                        sourceProgramRegion.requestStatus === "Confirmed" ? (
                          <button
                            onClick={() => copyToClipBoard(program.sourceProgram.affiliateLink, regionId)}
                            className={styles.copyButton}
                          >
                            {copyStatus === regionId ? (
                              "Copied!"
                            ) : (
                              <>
                                <span className="material-symbols-outlined">content_cut</span> Copy
                              </>
                            )}
                          </button>
                        ) : (
                          <button className={styles.pendingButton} disabled>
                            Pending
                          </button>
                        )
                      ) : (
                        <button onClick={() => joinProgramModal(program, region)} className={styles.joinButton}>
                          <span className="material-symbols-outlined">login</span> Join
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )) : (
          <a className={styles.noSource} href="../sources">Add a source to see program deals</a>
        )}
      </div>


<Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} className={styles.modal} overlayClassName={styles.overlay}>
  <div className={styles.modalContent}>
    <h3>Request to join program</h3>
    <div className={styles.programSource}>
      {selectedSource && (
        <>
          <img src={selectedSource.logo ? `/uploads/sourceLogos/${selectedSource.logo}` : placeholder} alt="Source" />
          <p>{selectedSource.name}</p>
        </>
      )}
    </div>
    <div className={styles.programSource}>
      {programToJoin && (
        <>
          <img src={programToJoin.program.brand.image ? `/uploads/brandLogos/${programToJoin.program.brand.image}` : placeholder} alt="Program" />
          <div className={styles.modalProgInfo}>
            <p>{programToJoin.program.name}</p>
            <p>{programToJoin.region.regionName}</p>
          </div>
        </>
      )}
    </div>
    {programToJoin && programToJoin.region.deal && (
      <div className={styles.dealContainer}>
        <p className={styles.standardDealHeader}>Deal Information</p>
        <div className={styles.deals}>
          <div><p><strong>CPA:</strong>{programToJoin.region.deal.CPA > 0 ? ` ${programToJoin.region.deal.CPA} €` : " -"}</p></div>
          <div><p><strong>Revenue Share:</strong> {programToJoin.region.deal.revshare > 0 ? ` ${(programToJoin.region.deal.revshare * 100).toFixed(2)} %` : " -"}</p></div>
          <div><p><strong>Baseline:</strong> {programToJoin.region.deal.baseline ? ` ${programToJoin.region.deal.baseline} €` : " -"}</p></div>
        </div>
      </div>
    )}
    <div className={styles.modalActions}>
      <button onClick={() => joinProgram(selectedSource.id, programToJoin.region.id)} className={styles.joinBtn}>Join</button>
      <button onClick={() => setShowModal(false)} className={styles.closeBtn}>Close</button>
    </div>
  </div>
</Modal>

    </div>
  );
};

export default ProgramsPage;
