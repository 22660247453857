import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import programService from '../../services/programService';
import Select from 'react-select';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Programs.module.scss';
import placeholder from '../../images/placeholder.jpg';
import Flag from 'react-world-flags';
import Modal from 'react-modal';
import EditStandradDealModal from './components/EditStandradDealModal';
import AddNewRegionDealModal from './components/AddNewRegionDealModal';

Modal.setAppElement('#root');

const Programs = () => {
  const [programs, setPrograms] = useState([]);
  const [brands, setBrands] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [regions, setRegions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showDealModal, setShowDealModal] = useState(false);
  const [selectedProgramRegion, setSelectedProgramRegion] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [showAddRegionModal, setShowAddRegionModal] = useState(false);

  const [filters, setFilters] = useState({
    brandId: null,
    verticalId: null,
    regionId: null,
    status: null,
  });

  const statusOptions = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
  ];

  useEffect(() => {
    fetchFilters();
  }, []);

  const openDealModal = (region, program) => {
    setSelectedProgramRegion(region);
    setSelectedProgram(program);
    setShowDealModal(true);
  };
  
  const openAddRegionModal = (program) => {
    setSelectedProgram(program);
    setShowAddRegionModal(true);
  };

  const handleAddRegionSubmit = async (newDealData) => {
    try {
      await adminService.addNewRegionDeal(selectedProgram.id, newDealData);
      alert('New region deal added successfully.');
      setShowAddRegionModal(false);
      fetchProgramsData();
    } catch (error) {
      console.error('Error adding new region deal:', error);
      alert('Failed to add new region deal.');
    }
  };

  useEffect(() => {
    fetchProgramsData();
  }, [currentPage, itemsPerPage]);

  const fetchFilters = async () => {
    try {
      const [brandsData, verticalsData, regionsData] = await Promise.all([
        programService.fetchAllBrands(),
        programService.fetchAllVerticals(),
        programService.fetchAllRegions(),
      ]);

      setBrands(brandsData.map((brand) => ({ value: brand.id, label: brand.name })));
      setVerticals(verticalsData.map((vertical) => ({ value: vertical.id, label: vertical.name })));
      setRegions(regionsData.map((region) => ({ value: region.id, label: region.name })));
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }
  };


  const handleSubmitDealChange = async (updatedDealData) => {
    try {
      await adminService.updateStandardDeal(selectedProgramRegion.id, updatedDealData);
      alert('Deal updated successfully.');
      closeDealModal();
      fetchProgramsData();
    } catch (error) {
      console.error('Error updating deal:', error);
      alert('Failed to update deal.');
    }
  };
  

  const fetchProgramsData = async () => {
    try {
      const data = await adminService.fetchPrograms(currentPage, itemsPerPage, { ...filters, searchTerm });
      setPrograms(data.programs || []);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  };

  const handleFilterChange = (selectedOption, { name }) => {
    setFilters({
      ...filters,
      [name]: selectedOption ? selectedOption.value : null,
    });
  };

  const handleApplyFilters = () => {
    if(currentPage != 1) {
      setCurrentPage(1);
    } else {
      fetchProgramsData();
    }
  };

  const closeDealModal = () => {
    setShowDealModal(false);
    setSelectedProgramRegion(null);
    setSelectedProgram(null);
  };

  return (
    <div className={styles.programsContainer}>
      <h1>Programs</h1>

      {/* Filters */}
      <div className={styles.filterContainer}>
        <div>
          <label>Status</label>
          <Select
            isClearable
            name="status"
            value={statusOptions.find((option) => option.value === filters.status)}
            onChange={handleFilterChange}
            options={statusOptions}
          />
        </div>
        <div>
          <label>Brand</label>
          <Select
            isClearable
            name="brandId"
            value={brands.find((option) => option.value === filters.brandId)}
            onChange={handleFilterChange}
            options={brands}
          />
        </div>
        <div>
          <label>Vertical</label>
          <Select
            isClearable
            name="verticalId"
            value={verticals.find((option) => option.value === filters.verticalId)}
            onChange={handleFilterChange}
            options={verticals}
          />
        </div>
        <div>
          <label>Region</label>
          <Select
            isClearable
            name="regionId"
            value={regions.find((option) => option.value === filters.regionId)}
            onChange={handleFilterChange}
            options={regions}
          />
        </div>
      </div>

      {/* Search Input */}
      <div className={styles.searchContainer}>
        <input
          type="text"
          placeholder="Search programs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={handleApplyFilters} className={styles.applyFilters}>Apply Filters</button>
      </div>

      {/* Programs Cards */}
      <div className={styles.cardsContainer}>
        {programs.map((program) => (
          <div className={styles.programCard} key={program.id}>
            {/* Program Details */}
            <div className={styles.programDetails}>
              <img
                src={program.Brand.image ? `/uploads/brandLogos/${program.Brand.image}` : placeholder}
                alt={program.name}
                className={styles.programImage}
              />
              <div className={styles.programInfo}>
                <h2>{program.name}</h2>
                <p className={styles.status}>Status: {program.status !== 'Active' ?
                    <div className={styles.red}>
                      <span className="material-symbols-outlined">cancel</span>
                    </div> :
                    <div className={styles.green}>
                      <span className="material-symbols-outlined">check_circle</span>
                    </div>
                }</p>
                <p>{program.Brand.name}</p>
                <p>{program.Verticals.map(v => v.name).join(", ")}</p>
                {program.aggregatedData && <p>(Aggregated Data)</p>}
                {program.status === "Active" && <button
  className={styles.addCountryBtn}
  onClick={() => openAddRegionModal(program)}
>
  Add New Region
</button>}
              </div>
            </div>

            <div className={styles.regionsContainer}>
              <div className={`${styles.regionRow} ${styles.headerRow}`}>
                <div className={styles.regionCell}>Region</div>
                <div className={styles.regionCell}>Description</div>
                <div className={styles.regionCell}>Baseline</div>
                <div className={styles.regionCell}>Your CPA</div>
                <div className={styles.regionCell}>CPA</div>
                <div className={styles.regionCell}>Your Revshare</div>
                <div className={styles.regionCell}>Revshare</div>
                <div className={styles.regionCell}>Action</div>
              </div>
              {program.ProgramRegions.map((region) => (
                <div key={region.id} className={styles.regionRow}>
                  <div className={styles.regionCell}>
                    <Flag code={region.Region.shortCode} style={{ width: '30px', height: '20px' }} alt="flag" />
                  </div>
                  <div className={styles.regionCell}>{region.description || 'N/A'}</div>
                  <div className={styles.regionCell}>
                    {region.ProgramRegionDeals.length > 0 ? `${region.ProgramRegionDeals[0].Deal.baseline} €` : 'N/A'}
                  </div>
                  <div className={styles.regionCell}>
                    {region.ProgramRegionDeals.length > 0 ? `${region.ProgramRegionDeals[0].Deal.CPAbeforeCommission} €` : 'N/A'}
                  </div>
                  <div className={styles.regionCell}>
                    {region.ProgramRegionDeals.length > 0 ? `${region.ProgramRegionDeals[0].Deal.CPA} €` : 'N/A'}
                  </div>
                  <div className={styles.regionCell}>
                    {region.ProgramRegionDeals.length > 0 ? `${(region.ProgramRegionDeals[0].Deal.revShareBeforeCommission * 100).toFixed(2)}%` : 'N/A'}
                  </div>
                  <div className={styles.regionCell}>
                    {region.ProgramRegionDeals.length > 0 ? `${(region.ProgramRegionDeals[0].Deal.revshare * 100).toFixed(2)}%` : 'N/A'}
                  </div>
                  <div className={styles.regionCell}>
                    <button onClick={() => openDealModal(region, program)} className={styles.editDeal}>Edit Deal</button>
                    </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      {showDealModal && (
  <EditStandradDealModal
    isOpen={showDealModal}
    onRequestClose={closeDealModal}
    selectedProgram={selectedProgram}
    selectedProgramRegion={selectedProgramRegion}
    onSubmit={handleSubmitDealChange}
  />
)}
{showAddRegionModal && (
  <AddNewRegionDealModal
    isOpen={showAddRegionModal}
    onRequestClose={() => setShowAddRegionModal(false)}
    selectedProgram={selectedProgram}
    onSubmit={handleAddRegionSubmit}
  />
)}


      <PaginationComponent
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={(event, newPage) => setCurrentPage(newPage)}
      />
    </div>
  );
};

export default Programs;
