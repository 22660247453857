import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import authService from '../../services/authService';
import sourceService from '../../services/sourceService';
import styles from './PieCharts.module.scss';
import 'chart.js/auto';

const PieCharts = () => {
  const [pieData, setPieData] = useState(null);
  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState(null);

  // Hämta user sources och piechart data
  useEffect(() => {
    fetchUserSources();
  }, []);

  useEffect(() => {
    fetchPieChartData();
  }, [selectedSource]);

  const fetchUserSources = async () => {
    try {
      const userSources = await sourceService.getUserSources();
      setSources(userSources);
    } catch (error) {
      console.error('Error fetching user sources:', error);
    }
  };

  const fetchPieChartData = async () => {
    try {
      const data = await authService.getDashboardPieChartData(selectedSource);
      setPieData(data);
    } catch (error) {
      console.error('Error fetching pie chart data:', error);
    }
  };

  const handleSourceChange = (event) => {
    const sourceId = event.target.value;
    setSelectedSource(sourceId === 'all' ? null : sourceId);
  };

  if (!pieData) {
    return <div>Loading Pie Charts...</div>;
  }

  // Funktion för att filtrera bort värden som är 0
  const filterData = (data) => data.filter((item) => item.value > 0);

  // Funktion för att skapa piechart-data
  const createChartData = (data, label) => {
    const filteredData = filterData(data);
    return {
      labels: filteredData.map((item) => item.name),
      datasets: [
        {
          label,
          data: filteredData.map((item) => item.value),
          backgroundColor: [
            '#14213d',  // Mörkblå
            '#FCA311',  // Guldgul
            '#264653',  // Petrolblå
            '#007f5f',  // Skogsgrön
            '#e76f51',  // Laxrosa
            '#ffba08',  // Klar gul
            '#8d99ae',  // Mörkgrå
            '#1d3557',  // Djupblå
            '#ff6700',  // Klarröd-orange
            '#6a4c93',  // Lila
            '#2a9d8f',  // Tealgrön
            '#f4a261',  // Sandig orange
            '#e9c46a',  // Mjuk gul
            '#003049',  // Djup marinblå
            '#9d0208',  // Djup röd
            '#d62828',  // Klar röd
            '#6a040f',  // Vinröd
            '#fb5607',  // Klarröd-orange
            '#3a0ca3',  // Djuplila
            '#001219'   // Nästan svart blå
          ]
          
          
        },
      ],
    };
  };

  // Funktion för att skapa legender som en lista
  const createLegend = (data) => {
    const filteredData = filterData(data);
    return (
      <ul className={styles.legendList}>
        {filteredData.map((item, index) => (
          <li key={index} className={styles.legendItem}>
            <span
              className={styles.legendColor}
              style={{
                backgroundColor: [
                    '#14213d',  // Mörkblå
                    '#FCA311',  // Guldgul
                    '#264653',  // Petrolblå
                    '#007f5f',  // Skogsgrön
                    '#e76f51',  // Laxrosa
                    '#ffba08',  // Klar gul
                    '#8d99ae',  // Mörkgrå
                    '#1d3557',  // Djupblå
                    '#ff6700',  // Klarröd-orange
                    '#6a4c93',  // Lila
                    '#2a9d8f',  // Tealgrön
                    '#f4a261',  // Sandig orange
                    '#e9c46a',  // Mjuk gul
                    '#003049',  // Djup marinblå
                    '#9d0208',  // Djup röd
                    '#d62828',  // Klar röd
                    '#6a040f',  // Vinröd
                    '#fb5607',  // Klarröd-orange
                    '#3a0ca3',  // Djuplila
                    '#001219'   // Nästan svart blå
                  ]
                  
                  [index],
              }}
            ></span>
            {item.name}: {item.value}
          </li>
        ))}
      </ul>
    );
  };

  const getTextColor = (backgroundColor) => {
    // Konvertera färgen till RGB-komponenter
    const rgb = hexToRgb(backgroundColor);
    // Beräkna ljusstyrka enligt standardformel
    const brightness = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b);
    // Returnera vit text för mörka bakgrunder, annars svart text
    return brightness > 160 ? '#000000' : '#ffffff';
  };
  
  const hexToRgb = (hex) => {
    // Ta bort eventuell hash (#) och konvertera hex till RGB
    const bigint = parseInt(hex.replace('#', ''), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return { r, g, b };
  };
  

  // Chart options för att dölja legender
  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        color: (context) => {
          const backgroundColor = context.dataset.backgroundColor[context.dataIndex];
          return getTextColor(backgroundColor);
        },
        font: {
          size: 14,
          weight: 'bold',
        },
        formatter: (value, context) => {
            const total = context.dataset.data.reduce((sum, val) => sum + val, 0);
            const percentage = (value / total) * 100;
        
            // Visa endast texten om sektorn är större än 5%
            return percentage > 10 ? `${value}` : '';
          },
      },
    },
    maintainAspectRatio: true,
  };
  

  return (
    <div className={styles.pieChartsContainer}>
      <div className={styles.filterContainer}>
        <h2>Country data for</h2>
        <select onChange={handleSourceChange} className={styles.sourceSelect}>
          <option value="all">All Sources</option>
          {sources.map((source) => (
            <option key={source.id} value={source.id}>
              {source.name}
            </option>
          ))}
        </select>
      </div>

      <div className={styles.pieCharts}>
  <div className={styles.pieChartWithLegend}>
    <div className={styles.pieChartContainer}>
      <h3>CPA Income per Country</h3>
      <div className={styles.chartAndLegends}>
        <Pie
          data={createChartData(pieData.cpaIncome.data, 'CPA Income')}
          options={chartOptions}
        />
        {createLegend(pieData.cpaIncome.data)}
      </div>
    </div>
  </div>

  <div className={styles.pieChartWithLegend}>
    <div className={styles.pieChartContainer}>
      <h3>Clicks per Country</h3>
      <div className={styles.chartAndLegends}>
        <Pie
          data={createChartData(pieData.clicks.data, 'Clicks')}
          options={chartOptions}
        />
        {createLegend(pieData.clicks.data)}
      </div>
    </div>
  </div>

  <div className={styles.pieChartWithLegend}>
    <div className={styles.pieChartContainer}>
      <h3>Signups per Country</h3>
      <div className={styles.chartAndLegends}>
        <Pie
          data={createChartData(pieData.signups.data, 'Signups')}
          options={chartOptions}
        />
        {createLegend(pieData.signups.data)}
      </div>
    </div>
  </div>

  <div className={styles.pieChartWithLegend}>
    <div className={styles.pieChartContainer}>
      <h3>FTD per Country</h3>
      <div className={styles.chartAndLegends}>
        <Pie
          data={createChartData(pieData.ftd.data, 'FTD')}
          options={chartOptions}
        />
        {createLegend(pieData.ftd.data)}
      </div>
    </div>
  </div>
</div>

    </div>
  );
};

export default PieCharts;
