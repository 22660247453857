import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./client/App";
import reportWebVitals from "./client/reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
document.addEventListener("wheel", function (event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

root.render(<App />);

reportWebVitals();
