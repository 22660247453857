import React, { useState, useEffect } from 'react';
import authService from '../../services/authService';
import EarningsChart from './EarningsChart';
import PieCharts from './PieCharts';
import styles from './Dashboard.module.scss';
import placeholder from '../../images/placeholder.jpg';

const Dashboard = () => {
  useEffect(() => {
    document.title = 'Savvy - Dashboard';
  }, []);

  const [summaryData, setSummaryData] = useState(null);
  const [hotPrograms, setHotPrograms] = useState([]);
  const [earningsData, setEarningsData] = useState([]);
  const [chartPeriod, setChartPeriod] = useState('last7days');

  useEffect(() => {
    fetchDashboardSummary();
    fetchDashboardHotPrograms();
  }, []);

  useEffect(() => {
    fetchDashboardEarnings();
  }, [chartPeriod]);

  const fetchDashboardSummary = async () => {
    try {
      const data = await authService.getDashboardSummary();
      setSummaryData(data);
    } catch (error) {
      console.error('Error fetching dashboard summary data:', error);
    }
  };

  const fetchDashboardEarnings = async () => {
    try {
      const data = await authService.getDashboardEarnings(chartPeriod);
      setEarningsData(data);
    } catch (error) {
      console.error('Error fetching dashboard earnings data:', error);
    }
  };

  const fetchDashboardHotPrograms = async () => {
    try {
      const data = await authService.getDashboardHotPrograms();
      setHotPrograms(data);
    } catch (error) {
      console.error('Error fetching dashboard hot programs data:', error);
    }
  };

  const handleChartPeriodChange = (newPeriod) => {
    setChartPeriod(newPeriod);
  };

  if (!summaryData || !earningsData) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.dashboardContainer}>
      {/* Info-boxar för sammanfattningsdata */}
        <div className={styles.firstCol}>
        <div className={styles.infoBoxesContainer}>
        <div className={styles.infoBox}>
            <div>
            <h6>Revenue</h6>
            <span class="material-symbols-outlined">
            attach_money
            </span>
            </div>
            <h3>
              ${summaryData.totalEarnings.toFixed(2)}
            </h3>
        </div>
        <div className={styles.infoBox}>
            <div>
            <h6>Signups</h6>
            <span class="material-symbols-outlined">
group
</span>
            </div>
            <h3>
            {summaryData.totalSignups}            </h3>
        </div>
        <div className={styles.infoBox}>
            <div>
            <h6>FTD</h6>
            <span class="material-symbols-outlined">
payments
</span>
            </div>
            <h3>
            {summaryData.totalFTD}            </h3>
        </div>
      </div>
      <EarningsChart
          earningsData={earningsData}
          chartPeriod={chartPeriod}
          onPeriodChange={handleChartPeriodChange}
        />
      </div>

      <div className={styles.secoundCol}>
      <div className={styles.hotProgramsContainer}>
        
        <div>
            <h3>Top Performers</h3>
            </div>
        <div className={styles.hotProgramsList}>
          {hotPrograms.length > 0 ? hotPrograms.map((program, index) => (
            <div key={index} className={styles.hotProgramCard}>
                <div>
                <img
  src={program.brandImage ? `/uploads/brandLogos/${program.brandImage}` : placeholder}
  alt="Brand Logo"
/>
<p>
  {program.brandName} - {program.verticals.join(' ')}
</p>

                </div>
                <p>
                  ${program.earnings.toFixed(2)} this week
                </p>
            </div>
          )) : 
          <p>No programs performance yet</p>
          }
        </div>
      </div>
        </div>
        <div className={styles.pieChart}>
        <PieCharts />

        </div>

    </div>
  );
};

export default Dashboard;
