import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL;

const login = async (email, password) => {
  const response = await axios.post(
    API_URL + "user/login",
    {
      email,
      password,
    },
    {
      withCredentials: true,
    }
  );
  if (response.data.token) {
    return {
      token: response.data.token,
      role: response.data.role,
    };
  }
  return response.data;
};

const register = async (password, email) => {
  const response = await axios.post(
    API_URL + "user/register",
    {
      password,
      email,
      role: "User",
    },
    {
      withCredentials: true,
    }
  );

  if (response.data.token) {
    return {
      token: response.data.token,
      role: response.data.role,
    };
  }
};

const logout = async () => {
  try {
    await axios.get(API_URL + "user/logout", { withCredentials: true });
  } catch (error) {
    throw error;
  }
};

const authenticate = async () => {
  try {
    const response = await axios.get(API_URL + "user/authenticate", {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    return { authenticated: false, role: null };
  }
};

const changePassword = async (newPassword) => {
  try {
    const response = await axios.post(
      API_URL + "user/change-password",
      {
        newPassword,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const forgotPassword = async (email) => {
  try {
    const response = await axios.post(
      `${API_URL}user/forgot-password`,
      {
        email,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const resetPassword = async (token, newPassword) => {
  try {
    const response = await axios.post(
      API_URL + "user/reset-password",
      {
        token,
        newPassword,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const checkResetPassword = async (token) => {
  try {
    const response = await axios.get(
      API_URL + `user/check-reset-password/${token}`,
      {},
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to access reset password");
  }
};

const getDashboardSummary = async () => {
  try {
    const response = await axios.get(`${API_URL}user/dashboard/summary`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard summary data:", error);
    throw error;
  }
};

const getDashboardEarnings = async (period) => {
  try {
    const response = await axios.get(`${API_URL}user/dashboard/earnings`, {
      params: { period },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard earnings data:", error);
    throw error;
  }
};

const getDashboardPieChartData = async (sourceId = null) => {
  try {
    const response = await axios.get(`${API_URL}user/dashboard/pie-charts`, {
      params: { sourceId },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard hot programs data:", error);
    throw error;
  }
};

const getDashboardHotPrograms = async () => {
  try {
    const response = await axios.get(`${API_URL}user/dashboard/hot-programs`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching dashboard hot programs data:", error);
    throw error;
  }
};

export default {
  getDashboardPieChartData,
  getDashboardSummary,
  getDashboardEarnings,
  getDashboardHotPrograms,
  checkResetPassword,
  resetPassword,
  forgotPassword,
  changePassword,
  login,
  register,
  logout,
  authenticate,
  logout,
};
