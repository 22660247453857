// Earnings.jsx
import React, { useState, useEffect, useRef } from 'react';
import styles from './Earnings.module.scss';
import sourceService from '../../services/sourceService';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';

const Earnings = () => {
  useEffect(() => {
    document.title = 'Savvy - Earnings';
  }, []);

  const [earningsData, setEarningsData] = useState(null);
  const [loading, setLoading] = useState(true); // Added loading state
  const [appliedDateRange, setAppliedDateRange] = useState([]);
  const [tempDateRange, setTempDateRange] = useState([]);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const datepickerRef = useRef(null);

  useEffect(() => {
    fetchEarnings();
  }, [appliedDateRange]);

  const fetchEarnings = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const startDate =
        appliedDateRange.length > 0
          ? formatDate(appliedDateRange[0].startDate)
          : null;
      const endDate =
        appliedDateRange.length > 0
          ? formatDate(appliedDateRange[0].endDate)
          : null;
      const data = await sourceService.fetchEarnings(startDate, endDate);
      setEarningsData(data);
    } catch (error) {
      console.error('Error fetching earnings data:', error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  // Formattera datum som "YYYY-MM-DD" utan att använda `date-fns`
  const formatDate = (date) => {
    if (!date) return null;
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handleDateButtonClick = (option) => {
    const today = new Date();
    if (option === 'today') {
      setAppliedDateRange([
        { startDate: today, endDate: today, key: 'selection' },
      ]);
    } else if (option === 'thisWeek') {
      const firstDayOfWeek = new Date(
        today.setDate(today.getDate() - today.getDay() + 1)
      );
      const lastDayOfWeek = new Date(
        today.setDate(firstDayOfWeek.getDate() + 6)
      );
      setAppliedDateRange([
        { startDate: firstDayOfWeek, endDate: lastDayOfWeek, key: 'selection' },
      ]);
    } else if (option === 'all') {
      setAppliedDateRange([]);
    }
  };

  const handleSelectDateRange = (ranges) => {
    setTempDateRange([ranges.selection]);
  };

  const applyDateRange = () => {
    setAppliedDateRange(tempDateRange);
    setShowDatePicker(false);
  };

  const formatCurrency = (amount) => {
    return `${amount.toFixed(2)} €`;
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datepickerRef.current &&
        !datepickerRef.current.contains(event.target) &&
        !event.target.closest(`.${styles.dateContainer}`)
      ) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [datepickerRef]);

  return (
    <div className={styles.earningsContainer}>
      <div className={styles.filterForm}>
        <div className={styles.dateFilter}>
          <div className={styles.dateButtons}>
            <button onClick={() => handleDateButtonClick('today')}>Today</button>
            <button onClick={() => handleDateButtonClick('thisWeek')}>
              This Week
            </button>
            <button onClick={() => handleDateButtonClick('all')}>All</button>
            <div className={styles.dateContainer}>
              <button
                onClick={() => setShowDatePicker(!showDatePicker)}
                className={styles.datePickerBtn}
              >
                <DateRangeOutlinedIcon className={styles.outlined} />
                <DateRangeIcon className={styles.filled} />
              </button>
              {appliedDateRange.length > 0 && (
                <span className={styles.dateDisplay}>
                  {formatDate(appliedDateRange[0].startDate)} -{' '}
                  {formatDate(appliedDateRange[0].endDate)}
                </span>
              )}
            </div>
          </div>
          {showDatePicker && (
            <div ref={datepickerRef} className={styles.datepicker}>
              <DateRange
                ranges={
                  tempDateRange.length > 0
                    ? tempDateRange
                    : [
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: 'selection',
                        },
                      ]
                }
                onChange={handleSelectDateRange}
                maxDate={new Date()}
                moveRangeOnFirstSelection={false}
                editableDateInputs={true}
              />
              <button onClick={applyDateRange} className={styles.dateBtn}>
                Apply
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={styles.earningsContent}>
        {loading ? (
          <p>Loading earnings data...</p>
        ) : earningsData && earningsData.totalIncome > 0 ? (
          <>
            <div className={styles.totalEarnings}>
              <h2>Total Earnings</h2>
              <div className={styles.earningsBreakdown}>
                <div>
                  <span>CPA Income:</span>
                  <span>{formatCurrency(earningsData.totalCPAIncome)}</span>
                </div>
                <div>
                  <span>RevShare Income:</span>
                  <span>{formatCurrency(earningsData.totalRevShareIncome)}</span>
                </div>
                <div>
                  <span>
                    <strong>Total Income:</strong>
                  </span>
                  <span>
                    <strong>{formatCurrency(earningsData.totalIncome)}</strong>
                  </span>
                </div>
              </div>
            </div>

            {earningsData.breakdownBySource.length > 0 && (
              <div className={styles.breakdownBySource}>
                <table className={styles.breakdownTable}>
                  <thead>
                    <tr>
                      <th>Source</th>
                      <th>CPA Income</th>
                      <th>RevShare Income</th>
                      <th>Total Income</th>
                    </tr>
                  </thead>
                  <tbody>
                    {earningsData.breakdownBySource.map((sourceData) => (
                      <tr key={sourceData.sourceId}>
                        <td>{sourceData.sourceName}</td>
                        <td>{formatCurrency(sourceData.totalCPAIncome)}</td>
                        <td>{formatCurrency(sourceData.totalRevShareIncome)}</td>
                        <td>
                          {formatCurrency(
                            sourceData.totalCPAIncome +
                              sourceData.totalRevShareIncome
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        ) : (
          <p>No earnings data available for the selected period.</p>
        )}
      </div>
    </div>
  );
};

export default Earnings;
