import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFound.css';

const NotFound = () => {
  useEffect(() => {
    document.title = 'Savvy - Not Found';
  }, []);  
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/'); // Navigerar tillbaka till startsidan
  };

  return (
    <div className="notfound-container">
      <h1 className="notfound-title">404 - Page Not Found</h1>
      <p className="notfound-message">Sorry, the page you are looking for does not exist.</p>
      <button className="notfound-button" onClick={handleGoHome}>
        Go to Homepage
      </button>
    </div>
  );
};

export default NotFound;
