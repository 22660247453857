import React, { useState, useEffect } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import Modal from 'react-modal';
import adminService from './../../services/adminService';
import styles from './ProgramRequests.module.scss';
import placeholder from '../../images/placeholder.jpg';
import { Tooltip } from '@mui/material';

Modal.setAppElement('#root');

const ProgramRequests = () => {
  const [programRequests, setProgramRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [revshare, setRevshare] = useState('0.00');
  const [cpa, setCpa] = useState(0);
  const [affiliateLink, setAffiliateLink] = useState('');
  const [var1, setVar1] = useState('');
  const [var2, setVar2] = useState('');
  const [isAffiliateLinkReadOnly, setIsAffiliateLinkReadOnly] = useState(false);
  const [isVar1ReadOnly, setIsVar1ReadOnly] = useState(false);
  const [isVar2ReadOnly, setIsVar2ReadOnly] = useState(false);
  const [useStandardDeal, setUseStandardDeal] = useState(true);
  const [dealMessage, setDealMessage] = useState('');
  const [regionDeal, setRegionDeal] = useState(null); // Added state for regionDeal

  const fetchProgramRequests = async () => {
    try {
      const data = await adminService.fetchProgramRequests();
      setProgramRequests(data);
    } catch (error) {
      console.error('Error fetching program requests:', error);
    }
  };

  useEffect(() => {
    fetchProgramRequests();
  }, []);

  const openModal = (request) => {
    setSelectedRequest(request);

    // Handle affiliateLink
    if (request.SourceProgram.affiliateLink) {
      setAffiliateLink(request.SourceProgram.affiliateLink);
      setIsAffiliateLinkReadOnly(true);
    } else {
      setAffiliateLink('');
      setIsAffiliateLinkReadOnly(false);
    }

    // Handle var1 and var2
    if (request.SourceProgram.var1) {
      setVar1(request.SourceProgram.var1);
      setIsVar1ReadOnly(true);
    } else {
      setVar1('');
      setIsVar1ReadOnly(false);
    }

    if (request.SourceProgram.var2) {
      setVar2(request.SourceProgram.var2);
      setIsVar2ReadOnly(true);
    } else {
      setVar2('');
      setIsVar2ReadOnly(false);
    }

    // Handle regionDeal
    const regionDeal =
      request?.ProgramRegion?.ProgramRegionDeals &&
      request.ProgramRegion.ProgramRegionDeals.length > 0
        ? request.ProgramRegion.ProgramRegionDeals[0].Deal
        : null;

    setRegionDeal(regionDeal); // Store regionDeal in state

    if (regionDeal) {
      setDealMessage('Regionen har en specifik deal. Vänligen kontrollera värdena.');
      setRevshare(((regionDeal.revshare || 0) * 100).toFixed(2));
      setCpa(regionDeal.CPA || 0);
    } else {
      setDealMessage('Ingen specifik deal är kopplad till denna region.');
      setRevshare('0.00');
      setCpa(0);
    }

    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedRequest(null);
    setRegionDeal(null); // Reset regionDeal
  };

  const handleAccept = async () => {
    if (selectedRequest) {
      try {
        await adminService.acceptProgramRequest(selectedRequest.id, {
          revshare: parseFloat(revshare) / 100,
          cpa,
          affiliateLink,
          var1,
          var2,
          useStandardDeal,
        });
        fetchProgramRequests();
        closeModal();
      } catch (error) {
        console.error('Error accepting program request:', error);
      }
    }
  };

  const handleDeny = async () => {
    if (selectedRequest) {
      try {
        await adminService.denyProgramRequest(selectedRequest.id, selectedRequest.Program.id);
        fetchProgramRequests();
        closeModal();
      } catch (error) {
        console.error('Error denying program request:', error);
      }
    }
  };

  const handleUseStandardDealChange = (event) => {
    setUseStandardDeal(event.target.checked);
    if (event.target.checked && regionDeal) {
      setRevshare(((regionDeal.revshare || 0) * 100).toFixed(2));
      setCpa(regionDeal.CPA || 0);
    }
  };

  const handleRevshareChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setRevshare(value.toFixed(2));
  };

  return (
    <div className={styles.programRequestsContainer}>
      <div className={styles.cardGrid}>
        {programRequests.map((request) => (
          <Card className={styles.programRequestCard} key={request.id}>
            <Card.Body>
              <p className={styles.requestDate}>{new Date(request.createdAt).toLocaleDateString()}</p>
              <div className={styles.programRequestContent}>
                <div className={styles.sourceInfo}>
                  <img
                    src={
                      request.SourceProgram.Source.logo
                        ? `/uploads/sourceLogos/${request.SourceProgram.Source.logo}`
                        : placeholder
                    }
                    alt={request.SourceProgram.Source.url}
                    className={styles.logo}
                  />
                  <p>{request.SourceProgram.Source.name}</p>
                </div>

                <div className={styles.arrow}>
                  <span className="material-symbols-outlined">arrow_forward</span>
                </div>

                {/* Program section */}
                <div className={styles.programInfo}>
                  <img
                    src={
                      request.ProgramRegion.Program.Brand.image
                        ? `/uploads/brandLogos/${request.ProgramRegion.Program.Brand.image}`
                        : placeholder
                    }
                    alt={request.ProgramRegion.Program.name}
                    className={styles.logo}
                  />
                  <p>{request.ProgramRegion.Program.name}</p>
                </div>
                <div>
                  <Button
                    variant="primary"
                    onClick={() => openModal(request)}
                    className={styles.respondButton}
                  >
                    Respond
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        ))}
      </div>

      {/* Modal for showing more details and updating commission */}
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modalContent}>
          <h3>Program Request</h3>
          {selectedRequest && (
            <div>
              <div className={styles.sourceProgramInfo}>
                <div className={styles.firstInfo}>
                  <p className={styles.infoLabel}>Source</p>
                  <div className={styles.imageText}>
                    <img
                      src={
                        selectedRequest.SourceProgram.Source.logo
                          ? `/uploads/sourceLogos/${selectedRequest.SourceProgram.Source.logo}`
                          : placeholder
                      }
                      className={styles.logo}
                    />
                    <div className={styles.mainInfo}>
                      <p>{selectedRequest.SourceProgram.Source.name}</p>
                      <p>{selectedRequest.SourceProgram.Source.url}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.firstInfo}>
                  <p className={styles.infoLabel}>Program</p>
                  <div className={styles.imageText}>
                    <img
                      src={
                        selectedRequest.ProgramRegion.Program.Brand.image
                          ? `/uploads/brandLogos/${selectedRequest.ProgramRegion.Program.Brand.image}`
                          : placeholder
                      }
                      className={styles.logo}
                    />
                    <div className={styles.mainInfo}>
                      <p>{selectedRequest.ProgramRegion.Program.name}</p>
                      <p>{selectedRequest.ProgramRegion.Program.Brand.name}</p>
                      <p>{'Status: ' + selectedRequest.ProgramRegion.Program.status}</p>
                      <p>{selectedRequest.ProgramRegion.Program.Verticals.map((v) => v.name).join(', ')}</p>
                      <p>{selectedRequest.ProgramRegion.Region.name}</p>
                    </div>
                  </div>
                </div>
                <div className={styles.standardDeal}>
                  <p>
                    <strong>Their CPA: </strong>
                    {regionDeal ? `${regionDeal.CPA}€` : '-'}
                  </p>
                  <p>
                    <strong>Our CPA: </strong>
                    {regionDeal ? `${regionDeal.CPAbeforeCommission}€` : '-'}
                  </p>
                  <p>
                    <strong>Their Revenue share: </strong>
                    {regionDeal ? `${(regionDeal.revshare * 100).toFixed(2)}%` : '-'}
                  </p>
                  <p>
                    <strong>Our Revenue share: </strong>
                    {regionDeal ? `${(regionDeal.revShareBeforeCommission * 100).toFixed(2)}%` : '-'}
                  </p>
                  <p>
                    <strong>Baseline: </strong>
                    {regionDeal ? `${regionDeal.baseline}€` : '-'}
                  </p>
                </div>
              </div>

              <div className={styles.inputContainer}>
                <Form>
                  <Form.Group controlId="formAffiliateLink" className={styles.affiliateLink}>
                    <Form.Label>Affiliate Link</Form.Label>
                    <Form.Control
                      type="text"
                      value={affiliateLink}
                      onChange={(e) => setAffiliateLink(e.target.value)}
                      disabled={isAffiliateLinkReadOnly}
                    />
                  </Form.Group>
                  <Form.Group controlId="formVar1">
                    <Form.Label>
                      Var1
                      <Tooltip
                        title={
                          'Var1 and Var2 are required for some programs to map data from APIs. Look at the docs for your program. For example, Pinnacle requires var1 to have the siteId for the site in the Pinnacle dashboard.'
                        }
                      >
                        <div className={styles.varTooltipContainer}>
                          <span className="material-symbols-outlined">info</span>
                        </div>
                      </Tooltip>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={var1}
                      onChange={(e) => setVar1(e.target.value)}
                      disabled={isVar1ReadOnly}
                    />
                  </Form.Group>

                  <Form.Group controlId="formVar2">
                    <Form.Label>Var2</Form.Label>
                    <Form.Control
                      type="text"
                      value={var2}
                      onChange={(e) => setVar2(e.target.value)}
                      disabled={isVar2ReadOnly}
                    />
                  </Form.Group>

                  {dealMessage && <p className={styles.dealMessage}>{dealMessage}</p>}
                  <Form.Group controlId="useStandardDeal" className={styles.useStandard}>
                    <Form.Label>Use Standard Deal</Form.Label>
                    <Form.Check
                      type="checkbox"
                      checked={useStandardDeal}
                      onChange={handleUseStandardDealChange}
                    />
                  </Form.Group>

                  <Form.Group controlId="formRevshare">
                    <Form.Label>Their Rev %</Form.Label>
                    <Form.Control
                      type="number"
                      value={revshare}
                      onChange={handleRevshareChange}
                      disabled={useStandardDeal}
                      step="0.01"
                    />
                  </Form.Group>

                  <Form.Group controlId="formCpa">
                    <Form.Label>Their CPA €</Form.Label>
                    <Form.Control
                      type="number"
                      value={cpa}
                      onChange={(e) => setCpa(e.target.value)}
                      disabled={useStandardDeal}
                    />
                  </Form.Group>
                </Form>

                <div className={styles.buttonGroup}>
                  <Button variant="primary" className={styles.acceptButton} onClick={handleAccept}>
                    Accept
                  </Button>
                  <div className={styles.rightButtonGroup}>
                    <Button variant="danger" className={styles.denyButton} onClick={handleDeny}>
                      Deny
                    </Button>
                    <Button variant="secondary" className={styles.closeButton} onClick={closeModal}>
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default ProgramRequests;
