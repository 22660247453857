import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import styles from './AddNewRegionDealModal.module.scss';
import programService from '../../../services/programService';

const AddNewRegionDealModal = ({
  isOpen,
  onRequestClose,
  selectedProgram,
  onSubmit,
}) => {
  const [dealData, setDealData] = useState({
    baseline: '',
    CPAbeforeCommission: '',
    cpa: '',
    revShareBeforeCommission: '',
    revshare: '',
    description: ''
  });
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);

  // Hämta alla regioner när modalen öppnas
  useEffect(() => {
    fetchAllRegions();
  }, []);

  const fetchAllRegions = async () => {
    try {
      const regionsData = await programService.fetchAllRegions();
      setRegions(regionsData.map((region) => ({
        value: region.id,
        label: region.name,
      })));
    } catch (error) {
      console.error('Error fetching regions:', error);
    }
  };

  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption);
  };

  const handleDealChange = (field, value) => {
    setDealData({ ...dealData, [field]: value });
  };

  const handleSubmit = () => {
    if (!selectedRegion) {
      alert('Please select a region.');
      return;
    }

    const newDealData = {
      regionId: selectedRegion.value,
      regionName: selectedRegion.label,
      ...dealData,
    };

    onSubmit(newDealData);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modalContent}>
        <h3>Add New Region Deal</h3>
        <div>
          <p><strong>Program: </strong>{selectedProgram?.name}</p>
        </div>

        <div className={styles.formField}>
          <label htmlFor="region">Select Region</label>
          <Select
            id="region"
            value={selectedRegion}
            onChange={handleRegionChange}
            options={regions}
            placeholder="Choose a region..."
          />
        </div>

        <div className={styles.inputGroup}>
        <div className={styles.formField}>
            <label htmlFor="baseline">Description</label>
            <input
            className={styles.input}
              id="baseline"
              type="text"
              value={dealData.description}
              onChange={(e) => handleDealChange('description', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="baseline">Baseline</label>
            <input
            className={styles.input}
              id="baseline"
              type="number"
              value={dealData.baseline}
              onChange={(e) => handleDealChange('baseline', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="CPAbeforeCommission">CPA Before Commission</label>
            <input
                        className={styles.input}
              id="CPAbeforeCommission"
              type="number"
              value={dealData.CPAbeforeCommission}
              onChange={(e) => handleDealChange('CPAbeforeCommission', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="cpa">CPA</label>
            <input
                        className={styles.input}
              id="cpa"
              type="number"
              value={dealData.cpa}
              onChange={(e) => handleDealChange('cpa', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="revShareBeforeCommission">Revshare Before Commission</label>
            <input
                        className={styles.input}
              id="revShareBeforeCommission"
              type="number"
              value={dealData.revShareBeforeCommission}
              onChange={(e) => handleDealChange('revShareBeforeCommission', e.target.value)}
            />
          </div>

          <div className={styles.formField}>
            <label htmlFor="revshare">Revshare</label>
            <input
                        className={styles.input}

              id="revshare"
              type="number"
              value={dealData.revshare}
              onChange={(e) => handleDealChange('revshare', e.target.value)}
            />
          </div>
        </div>

        <div className={styles.modalActions}>
          <button onClick={handleSubmit} className={styles.submitButton}>Submit</button>
          <button onClick={onRequestClose} className={styles.cancelButton}>Cancel</button>
        </div>
      </div>
    </Modal>
  );
};

export default AddNewRegionDealModal;
