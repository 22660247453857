import React, { useState, useEffect, useRef } from 'react';
import styles from './Performance.module.scss';
import programService from '../../services/programService';
import sourceService from '../../services/sourceService';
import Select from 'react-select';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Flag from 'react-world-flags';
import Tooltip from '@mui/material/Tooltip';
import PaginationComponent from '../../components/PaginationComponent';

const Performance = () => {
  useEffect(() => {
    document.title = 'Savvy - Performance';
  }, []);

  const [sources, setSources] = useState([]);
  const [brands, setBrands] = useState([]);

  // Faktiska filter som används vid API-anrop
  const [perDay, setPerDay] = useState(false);

  // Tillfälliga filter som användaren redigerar
  const [selectedSource, setSelectedSource] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [appliedDateRange, setAppliedDateRange] = useState(null);
  const [breakdownOption, setBreakdownOption] = useState('regions');
  const [tempPerDay, setTempPerDay] = useState(false);

  const [performanceData, setPerformanceData] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showBreakdownMenu, setShowBreakdownMenu] = useState(false);

  const datepickerRef = useRef(null);
  const breakdownMenuRef = useRef(null);
  const breakdownButtonRef = useRef(null);

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Funktion för att hämta initial data
  const fetchInitialData = async () => {
    try {
      const [sourcesData, brandsData] = await Promise.all([
        sourceService.getAllSources(),
        programService.fetchAllBrands(),
      ]);
      setSources(sourcesData);
      setBrands(
        brandsData.map((brand) => ({ value: brand.id, label: brand.name }))
      );

      // Hämta prestandadata med standardfilter
      fetchPerformance(
        null, // sourceId
        null, // brandId
        null, // dateRange
        breakdownOption,
        perDay,
        currentPage,
        itemsPerPage
      );
    } catch (error) {
      console.error('Error fetching initial data:', error);
    }
  };

  // Funktion för att hämta prestandadata
  const fetchPerformance = async (
    sourceId,
    brandId,
    dateRange,
    breakdownOption,
    perDay
  ) => {
    try {
      const response = await programService.fetchAllPerformance(
        sourceId,
        brandId,
        dateRange,
        breakdownOption,
        perDay
      );
      setPerformanceData(response.data);
      setCurrentPage(1); // Återställ till första sidan
    } catch (error) {
      console.error('Error fetching performance data:', error);
    }
  };

  // Funktion som anropas när användaren klickar på "Apply Filters"
  const handleApplyFilters = () => {
    setSelectedSource(selectedSource);
    setSelectedBrandId(selectedBrandId);
    setAppliedDateRange(appliedDateRange);
    setBreakdownOption(breakdownOption);
    setPerDay(tempPerDay);

    fetchPerformance(
      selectedSource ? selectedSource.id : null,
      selectedBrandId,
      appliedDateRange,
      breakdownOption,
      tempPerDay
    );
  };


  // Handle clicks outside DatePicker
  useEffect(() => {
    const handleClickOutsideDatePicker = (event) => {
      if (
        datepickerRef.current &&
        !datepickerRef.current.contains(event.target) &&
        !event.target.closest(`.${styles.dateContainer}`)
      ) {
        setShowDatePicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideDatePicker);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDatePicker);
    };
  }, [datepickerRef]);

  // Handle clicks outside BreakdownMenu
  useEffect(() => {
    const handleClickOutsideBreakdownMenu = (event) => {
      if (
        breakdownMenuRef.current &&
        !breakdownMenuRef.current.contains(event.target) &&
        breakdownButtonRef.current &&
        !breakdownButtonRef.current.contains(event.target)
      ) {
        setShowBreakdownMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutsideBreakdownMenu);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideBreakdownMenu);
    };
  }, [breakdownMenuRef, breakdownButtonRef]); // Lägg till breakdownButtonRef här


  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #ced4da',
      borderRadius: '8px',
      padding: '2px',
      minHeight: '42px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#86b7fe',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#6c757d',
    }),
  };

  const handleSourceChange = (selectedOption) => {
    if (selectedOption) {
      const source = sources.find((src) => src.id === selectedOption.value);
      setSelectedSource(source);
    } else {
      setSelectedSource(null);
    }
  };

  const handleBrandChange = (selectedOption) => {
    setSelectedBrandId(selectedOption ? selectedOption.value : null);
  };

  const handleDateButtonClick = (option) => {
    const today = new Date();
    if (option === 'today') {
      setAppliedDateRange({ startDate: today, endDate: today });
    } else if (option === 'thisWeek') {
      const firstDayOfWeek = new Date();
      firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1);
      const lastDayOfWeek = new Date();
      lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
      setAppliedDateRange({ startDate: firstDayOfWeek, endDate: lastDayOfWeek });
    } else if (option === 'all') {
      setAppliedDateRange(null);
    }
  };

  const handleSelectDateRange = (ranges) => {
    setAppliedDateRange({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    });
  };

  const formatDateDisplay = (startDate, endDate) => {
    if (!startDate || !endDate) return;
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const formattedStart = formatDate(startDate);
    const formattedEnd = formatDate(endDate);
    if (formattedStart === formattedEnd) {
      return formattedStart;
    }
    return `${formattedStart} to ${formattedEnd}`;
  };

  const getStatDisplayValue = (statValue) => (statValue != null ? statValue : '-');

    // Beräkna total antal sidor
    const totalPages = Math.ceil(performanceData.length / itemsPerPage);

    // Funktion för att hantera sidbyte
    const handlePageChange = (event, value) => {
      setCurrentPage(value);
    };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = performanceData.slice(indexOfFirstItem, indexOfLastItem);


  return (
    <div className={styles.performanceContainer}>
      <div className={styles.filterForm}>
        <div>
          <label>Select source</label>
          <Select
            name="source"
            value={
              selectedSource
                ? { value: selectedSource.id, label: selectedSource.name }
                : null
            }
            onChange={handleSourceChange}
            options={sources.map((source) => ({
              value: source.id,
              label: source.name,
            }))}
            styles={customSelectStyles}
            placeholder="Select source"
            isClearable
          />
        </div>
        <div>
          <label>Brand</label>
          <Select
            name="brandId"
            value={
              brands.find((option) => option.value === selectedBrandId) || null
            }
            onChange={handleBrandChange}
            options={brands}
            isClearable
            placeholder="Select a brand"
            styles={customSelectStyles}
          />
        </div>
        <div className={styles.dateFilter}>
          <div className={styles.dateButtons}>
            <button onClick={() => handleDateButtonClick('today')}>Today</button>
            <button onClick={() => handleDateButtonClick('thisWeek')}>
              This Week
            </button>
            <button onClick={() => handleDateButtonClick('all')}>All</button>
            <div className={styles.dateContainer}>
              <button
                onClick={() => setShowDatePicker(!showDatePicker)}
                className={styles.datePickerBtn}
              >
                <DateRangeOutlinedIcon className={styles.outlined} />
                <DateRangeIcon className={styles.filled} />
              </button>
              {appliedDateRange && (
                <span>
                  {formatDateDisplay(
                    appliedDateRange.startDate,
                    appliedDateRange.endDate
                  )}
                </span>
              )}
            </div>
          </div>

          {showDatePicker && (
            <div ref={datepickerRef} className={styles.datepicker}>
              <DateRange
                ranges={[
                  {
                    startDate: appliedDateRange?.startDate || new Date(),
                    endDate: appliedDateRange?.endDate || new Date(),
                    key: 'selection',
                  },
                ]}
                onChange={handleSelectDateRange}
                maxDate={new Date()}
                moveRangeOnFirstSelection={false}
                editableDateInputs={true}
              />
            </div>
          )}
        </div>
        <div className={styles.rightCell}>
        <div className={styles.breakdownContainer}>
        <button
            onClick={() => setShowBreakdownMenu(!showBreakdownMenu)}
            ref={breakdownButtonRef}
          >

          <span class="material-symbols-outlined">
filter_list
</span>
            Breakdown
          </button>
          {showBreakdownMenu && (
            <div ref={breakdownMenuRef} className={styles.breakdownMenu}>
              <div>
              <label>
                <select
                  value={breakdownOption}
                  onChange={(e) => setBreakdownOption(e.target.value)}
                >
                  <option value="programs">Programs</option>
                  <option value="regions">Regions</option>
                </select>
              </label>
              </div>
              <div>
              <label>
                <input
                  type="checkbox"
                  checked={tempPerDay}
                  onChange={(e) => setTempPerDay(e.target.checked)}
                />
                Per Day
              </label>
              </div>
            </div>
          )}
        </div>
        <button onClick={handleApplyFilters} className={styles.applyFiltersBtn}>
          Apply Filters
        </button>
      </div>
      </div>

<div className={styles.tableContainer}>
      <table className={styles.tableFullWidth}>
        <thead>
          <tr>
          {perDay && <th>Date</th>}
            <th>Program</th>
            <th>Regions</th>
            <th>Source</th>
            <th>Clicks</th>
            <th>Signups</th>
            <th>FTD</th>
            <th>QFTD</th>
            <th>CPA Before Commission</th>
            <th>CPA Income</th>
            <th>Rev Before Commission</th>
            <th>Rev Income</th>
          </tr>
        </thead>
        <tbody>
          {currentData.length > 0 ? (
            currentData.map((data, index) => {
              const regions =
                data.type === 'program' ? data.regions : [data.region];

              return (
                <tr key={index}>
                                    {perDay && <td>{data.stats.date}</td>}
                  <td>
                    {data.brandName} {data.verticals?.join(', ') || '-'}
                  </td>
                  <td>
                    <div className={styles.flags}>
                      {regions.length > 0 ? (
                        regions.map((region, idx) => (
                          <Tooltip key={idx} title={region.name} arrow>
                            <Flag code={region.shortCode} height="16" />
                          </Tooltip>
                        ))
                      ) : (
                        '-'
                      )}
                    </div>
                  </td>
                  <td>{data.sourceName}</td>
                  <td>{getStatDisplayValue(data.stats.clicks)}</td>
                  <td>{getStatDisplayValue(data.stats.signups)}</td>
                  <td>{getStatDisplayValue(data.stats.FTD)}</td>
                  <td>{getStatDisplayValue(data.stats.QFTD)}</td>
                  <td>
                    {getStatDisplayValue(
                      data.stats.CPAIncomeBeforeCommission != null
                        ? `${data.stats.CPAIncomeBeforeCommission.toFixed(2)} €`
                        : null
                    )}
                  </td>
                  <td>
                    {getStatDisplayValue(
                      data.stats.CPAIncome != null
                        ? `${data.stats.CPAIncome.toFixed(2)} €`
                        : null
                    )}
                  </td>
                  <td>
                    {getStatDisplayValue(
                      data.stats.revShareIncomeBeforeCommission != null
                        ? `${data.stats.revShareIncomeBeforeCommission.toFixed(2)} €`
                        : null
                    )}
                  </td>
                  <td>
                    {getStatDisplayValue(
                      data.stats.revShareIncome != null
                        ? `${data.stats.revShareIncome.toFixed(2)} €`
                        : null
                    )}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="9" className="text-center">
                <strong>No performance data available</strong>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
      <PaginationComponent
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />
    </div>
  );
};

export default Performance;
