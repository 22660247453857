import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate, Outlet, Link, useLocation } from 'react-router-dom';
import styles from './UserLayout.module.scss';
import authService from '../services/authService';
import { Tooltip } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import WebStoriesIcon from '@mui/icons-material/WebStories';
import WebStoriesOutlinedIcon from '@mui/icons-material/WebStoriesOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';
import LayersIcon from '@mui/icons-material/Layers';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PaymentsIcon from '@mui/icons-material/Payments';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';

const UserLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true); // Start with sidebar open
  const [showDropDown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();

  const getPageTitle = () => {
    const path = location.pathname;

    if (path === '/sources') {
      return { title: 'Sources', showBackButton: false };
    } else if (path === '/programs') {
      return { title: 'Programs', showBackButton: false };
    } else if (path === '/performance') {
      return { title: 'Performance', showBackButton: false };
    }else if (path === '/earnings') {
      return { title: 'Earnings', showBackButton: false };
    }else {
      return { title: 'Dashboard', showBackButton: false }; // Default title
    }
  };
  const pageTitleInfo = getPageTitle();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropDown);
  };

  const logout = async () => {
    await authService.logout();
    navigate('/login');
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setSidebarOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropDown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropDown]);

  return (
    <div className={styles.container}>
      <div className={`${styles.sidebar} ${sidebarOpen ? styles.open : styles.closed}`}>
        <div className={styles.header}>
          <Link to="/"><h1>S<span>avvy</span></h1></Link>
        </div>
        <div className={styles.sideMenu}>
          <ul>
            <li className={styles.sideMenuItem}>
              <NavLink to="/" className={({ isActive }) => isActive ? styles.active : undefined} onClick={handleLinkClick}>
              <DashboardIcon className={styles.filled} />
                <DashboardOutlinedIcon className={styles.outlined} />
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
                      <NavLink
            to="/sources"
            className={({ isActive }) =>
              isActive || window.location.pathname === '/sources' ? styles.active : undefined
            }
            onClick={handleLinkClick}
          >
                <WebStoriesIcon className={styles.filled} />
                <WebStoriesOutlinedIcon className={styles.outlined} />
                          <p>Sources</p>
          </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
                      <NavLink
            to="/programs"
            className={({ isActive }) =>
              isActive || window.location.pathname === '/programs' ? styles.active : undefined
            }
            onClick={handleLinkClick}
          >
          
                <LayersIcon className={styles.filled} />
                <LayersOutlinedIcon className={styles.outlined} />
                         <p>Programs</p>
          </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
                      <NavLink
            to="/performance"
            className={({ isActive }) =>
              isActive || window.location.pathname === '/performance' ? styles.active : undefined
            }
            onClick={handleLinkClick}
          >
              <InsertChartIcon className={styles.filled} />
                <InsertChartOutlinedIcon className={styles.outlined} />
                          <p>Performance</p>
          </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
                      <NavLink
            to="/earnings"
            className={({ isActive }) =>
              isActive || window.location.pathname === '/earnings' ? styles.active : undefined
            }
            onClick={handleLinkClick}
          >
                <PaymentsIcon className={styles.filled} />
                <PaymentsOutlinedIcon className={styles.outlined} />
                            <p>Earnings</p>
          </NavLink>
            </li>
          </ul>
        </div>
        <div className={styles.toggleSidebar} onClick={toggleSidebar}>
          <span className="material-symbols-outlined">
            chevron_left
          </span>
        </div>
      </div>
      <div className={styles.contentWrapper}>
      <div className={styles.content}>
        <div className={styles.topnav}>
        <h1>
              {pageTitleInfo.showBackButton && (
                <button onClick={() => navigate(-1)} className={styles.backButton}><span class="material-symbols-outlined">
                arrow_back
                </span></button>
              )}
              {pageTitleInfo.title}
            </h1>
          <ul className={styles.topMenu}>
              <li className={`${styles.topMenuItem} ${styles.profile}`}>
                <button onClick={toggleDropdown}>
                  <span className="material-symbols-outlined">person</span>
                </button>
                <div ref={dropdownRef} className={`${styles.dropdown} ${showDropDown ? styles.dropdownOpen : ''}`}>
                  {false &&
                  <div className={styles.dropdownItem}>
                    <NavLink to="/settings" onClick={() => setShowDropdown(false)}>
                      <span className="material-symbols-outlined">settings</span>
                      Settings
                    </NavLink>    
                  </div>
                  }

                  <div className={styles.dropdownItem}>
                    <button onClick={logout}>
                      <span className="material-symbols-outlined">logout</span>
                      Logout
                    </button>
                  </div>
                </div>
              </li>
          </ul>
        </div>
        <div className={styles.main}>
          <Outlet />
        </div>
      </div>
    </div>
    </div>

  );
};

export default UserLayout;
