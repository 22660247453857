import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import adminService from '../../services/adminService';
import programService from '../../services/programService';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './SourcePrograms.module.scss';
import Flag from 'react-world-flags';
import Modal from 'react-modal';
import EditSourceProgramDealModal from './components/EditSourceProgramDealModal';

Modal.setAppElement('#root');


const SourcePrograms = () => {
  const [programSources, setProgramSources] = useState([]);
  const [sources, setSources] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [brands, setBrands] = useState([]);
  const [verticals, setVerticals] = useState([]);
  const [regions, setRegions] = useState([]);
  const [filters, setFilters] = useState({
    sourceId: null,
    programId: null,
    brandId: null,
    verticalId: null,
    regionId: null,
    dealType: null,
    includeOld: false,
  });
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showLinkChangeModal, setLinkChangeModal] = useState(false);
  const [selectedProgramSource, setSelectedProgramSource] = useState(null);
  const [newAffiliateLink, setNewAffiliateLink] = useState('');
  const [showDealModal, setShowDealModal] = useState(false);


  useEffect(() => {
    fetchFilters();
    fetchSources();
    fetchPrograms();
  }, []);

  useEffect(() => {
    fetchSourceProgramsData();
  }, [currentPage, itemsPerPage]);

  const fetchFilters = async () => {
    try {
      const [brandsData, verticalsData, regionsData] = await Promise.all([
        programService.fetchAllBrands(),
        programService.fetchAllVerticals(),
        programService.fetchAllRegions(),
      ]);

      setBrands(brandsData.map((brand) => ({ value: brand.id, label: brand.name })));
      setVerticals(verticalsData.map((vertical) => ({ value: vertical.id, label: vertical.name })));
      setRegions(regionsData.map((region) => ({ value: region.id, label: region.name })));
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }
  };

  const fetchSources = async () => {
    try {
      const data = await adminService.fetchSources();
      setSources(data.sources || []);
    } catch (error) {
      console.error('Error fetching sources:', error);
    }
  };

  const fetchPrograms = async () => {
    try {
      const data = await adminService.fetchProgramsOptions();
      setPrograms(data || []);
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  };

  const fetchSourceProgramsData = async () => {
    try {
      const data = await adminService.fetchSourcePrograms(currentPage, itemsPerPage, filters);
      console.log(data)
      setProgramSources(data.regions || []);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('Error fetching programs:', error);
    }
  };

  const handleFilterChange = (selectedOption, { name }) => {
    setFilters({
      ...filters,
      [name]: selectedOption ? selectedOption.value : null,
    });
  };

  const handleApplyFilters = () => {
    setCurrentPage(1);
    fetchSourceProgramsData();
  };

  const handleClearFilters = () => {
    setFilters({
      sourceId: null,
      programId: null,
      brandId: null,
      verticalId: null,
      regionId: null,
      dealType: null,
    });
    setCurrentPage(1);
    fetchSourceProgramsData();
  };

  const changeLinkMobal = (region) => {
    setSelectedProgramSource(region);
    setLinkChangeModal(true);
  };

  const closeLinkChangeModal = () => {
    setLinkChangeModal(false);
    setSelectedProgramSource(null);
    setNewAffiliateLink('');
  };

  const handleSubmitLinkChange = async () => {
    if (!newAffiliateLink.trim()) {
      alert('Affiliate link is required.');
      return;
    }

    try {
      await adminService.changeAffiliateLink(selectedProgramSource.sourceProgramId, newAffiliateLink);
      alert('Affiliate link updated successfully.');
      closeLinkChangeModal();
      fetchSourceProgramsData();
    } catch (error) {
      console.error('Error changing affiliate link:', error);
      alert('Failed to update affiliate link.');
    }
  };

  const openDealModal = (programSource) => {
    setSelectedProgramSource(programSource);
    setShowDealModal(true);
  };
  

  const closeDealModal = () => {
    setShowDealModal(false);
    setSelectedProgramSource(null);

  };

  const handleSubmitDealChange = async (updatedDealData) => {
    try {
      updatedDealData.revshare = updatedDealData.revshare / 100 || 0;
      updatedDealData.revshareBefore = updatedDealData.revshareBefore / 100 || 0;

      await adminService.updateDeal(selectedProgramSource.id, updatedDealData);
      alert('Deal updated successfully.');
      closeDealModal();
      fetchSourceProgramsData();
    } catch (error) {
      console.error('Error updating deal:', error);
      alert('Failed to update deal.');
    }
  };
  

  return (
    <div className={styles.sourcesProgramContainer}>
      <h1>Sources Program</h1>

      {/* Filters */}
      <div className={styles.filterContainer}>
        <div className={styles.inputContainer}>
          <label>Source</label>
          <Select
            isClearable
            name="sourceId"
            options={sources.map((source) => ({ value: source.id, label: source.name }))}
            onChange={handleFilterChange}
          />
        </div>
        <div className={styles.inputContainer}>
          <label>Program</label>
          <Select
            isClearable
            name="programId"
            options={programs.map((program) => ({ value: program.id, label: program.name }))}
            onChange={handleFilterChange}
          />
        </div>
        <div className={styles.inputContainer}>
          <label>Brand</label>
          <Select
            isClearable
            name="brandId"
            options={brands}
            onChange={handleFilterChange}
          />
        </div>
        <div className={styles.inputContainer}>
          <label>Vertical</label>
          <Select
            isClearable
            name="verticalId"
            options={verticals}
            onChange={handleFilterChange}
          />
        </div>
        <div className={styles.inputContainer}>
          <label>Region</label>
          <Select
            isClearable
            name="regionId"
            options={regions}
            onChange={handleFilterChange}
          />
        </div>
        <div className={styles.inputContainer}>
          <label>Deal Type</label>
          <Select
            isClearable
            name="dealType"
            options={[
              { value: 'Standard', label: 'Standard' },
              { value: 'Custom', label: 'Custom' },
            ]}
            onChange={handleFilterChange}
          />
        </div>
        <div className={styles.filterActions}>
          <div className={styles.filterButtons}>
          <button onClick={handleApplyFilters} className={styles.applyButton}>Apply Filters</button>
          <button onClick={handleClearFilters} className={styles.clearButton}>Clear Filters</button>
          </div>
          <label>
  <input
    type="checkbox"
    checked={filters.includeOld}
    onChange={() =>
      setFilters((prevFilters) => ({
        ...prevFilters,
        includeOld: !prevFilters.includeOld,
      }))
    }
  />
  Include old deals
</label>

        </div>
                
      </div>

      {/* Table */}
      <div className={styles.tableContainer}>
      <table className={styles.tableFullWidth}>
          <thead>
            <tr>
              <th>Source</th>
              <th>Program</th>
              <th>Region</th>
              <th>Type</th>
              <th>Valid from</th>
              <th>Valid to</th>
              <th>Baseline</th>
              <th>CPA Before</th>
              <th>CPA</th>
              <th>Revshare Before</th>
              <th>Revshare</th>
              <th>Affiliate Link</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {programSources.map((programSource) => (
              <tr key={programSource.id}>
                <td>{programSource.source}</td>
                <td>{programSource.program}</td>
                <td>
                  <Flag code={programSource.region.shortCode} style={{ width: '30px', height: '20px' }} alt={programSource.region.name} />
                </td>
                <td>{programSource.dealType}</td>
                <td>
  {programSource.validFrom ? new Date(programSource.validFrom).toLocaleDateString() : "-"}
</td>
<td>
{programSource.validTo ? new Date(programSource.validTo).toLocaleDateString() : "-"}

</td>

                <td>{programSource.baseline} €</td>
                <td>{programSource.cpaBefore} €</td>
                <td>{programSource.cpa} €</td>
                <td>{programSource.revshareBefore} %</td>
                <td>{programSource.revshare} %</td>
                <td>{programSource.affiliateLink}</td>
                <td>
                  <div className={styles.tableActions}>
                  <button onClick={() => changeLinkMobal(programSource)} className={styles.changeLinkBtn} disabled={programSource.linkStatus !== "Active"}>Change Link</button>
                  <button onClick={() => openDealModal(programSource)} className={styles.changeDealBtn} disabled={programSource.requestStatus !== "Confirmed" || programSource.validTo !== null}>Edit Deal</button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showLinkChangeModal && (
        <Modal isOpen={showLinkChangeModal} onRequestClose={closeLinkChangeModal} className={styles.modal} overlayClassName={styles.modalOverlay}>
                  <div className={styles.modalContent}>

          <h3>Change Affiliate Link</h3>
          <div>
          <p><strong>Source: </strong>{selectedProgramSource?.source}</p>
          <p><strong>Program: </strong>{selectedProgramSource?.program}</p>
          </div>
          <p className={styles.warningText}>This link will change for all the regions connected to this source program.</p>

          <input
            type="text"
            value={newAffiliateLink}
            onChange={(e) => setNewAffiliateLink(e.target.value)}
            placeholder="Enter new affiliate link"
            required
          />
          <div className={styles.modalActions}>
            <button onClick={handleSubmitLinkChange} className={styles.submitButton}>Submit</button>
            <button onClick={closeLinkChangeModal} className={styles.cancelButton}>Cancel</button>
          </div>
          </div>

        </Modal>
      )}

{showDealModal && (
  <EditSourceProgramDealModal
    isOpen={showDealModal}
    onRequestClose={closeDealModal}
    selectedProgramSource={selectedProgramSource}
    onSubmit={handleSubmitDealChange}
  />
)}


      {/* Pagination */}
      <PaginationComponent
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        totalPages={totalPages}
        currentPage={currentPage}
        handlePageChange={(event, newPage) => setCurrentPage(newPage)}
      />
    </div>
  );
};

export default SourcePrograms;
